var App = {
    init: function () {
        this.scripts();
    },
    loaded: function () {},
    scripts: function () {
        // Is touch device?

        if ('ontouchstart' in window || window.navigator.MaxTouchPoints > 0 || window.navigator.msMaxTouchPoints > 0) {
            document.body.classList.add('touch');
        } else {
            document.body.classList.add('no-touch');
        }

        // Document has scrolled

        window.addEventListener('scroll', function () {
            if (window.pageYOffset >= 50) {
                document.body.classList.add('page-scrolled');
            } else {
                document.body.classList.remove('page-scrolled');
            }
        });

        // Mobile menu

        const menu_wrapper = document.querySelector('.page-header');
        const menu_button = menu_wrapper.querySelector('.menu-btn');

        if (menu_wrapper && menu_button) {
            menu_button.addEventListener('click', function () {
                menu_wrapper.classList.toggle('opened');
                document.body.classList.toggle('opened');
            });

            window.addEventListener('keyup', function (event) {
                if (event.keyCode == 27 && document.body.classList.contains('opened')) {
                    menu_wrapper.classList.remove('opened');
                    document.body.classList.remove('opened');
                }
            });
        }

        // To top

        const back_to_top_button = document.querySelector('.back-top');

        if (back_to_top_button) {
            document.querySelector('.back-top').addEventListener('click', function () {
                window.scrollTo(0, 0);
            });
        }
    }
};

document.addEventListener('DOMContentLoaded', function () {
    App.init();
});
window.addEventListener('load', function () {
    App.loaded();
});
